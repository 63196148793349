/* source-sans-pro-200 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'),
       url(./assets/fonts/source-sans-pro-v13-latin-200.woff2) format('woff2'), 
       url(./assets/fonts/source-sans-pro-v13-latin-200.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-200italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  src: local('Source Sans Pro ExtraLight Italic'), local('SourceSansPro-ExtraLightItalic'),
       url(./assets/fonts/source-sans-pro-v13-latin-200italic.woff2) format('woff2'), 
       url(./assets/fonts/source-sans-pro-v13-latin-200italic.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-300 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
       url(./assets/fonts/source-sans-pro-v13-latin-300.woff2) format('woff2'), 
       url(./assets/fonts/source-sans-pro-v13-latin-300.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-300italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'),
       url(./assets/fonts/source-sans-pro-v13-latin-300italic.woff2) format('woff2'), 
       url(./assets/fonts/source-sans-pro-v13-latin-300italic.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
       url(./assets/fonts/source-sans-pro-v13-latin-regular.woff2) format('woff2'), 
       url(./assets/fonts/source-sans-pro-v13-latin-regular.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
       url(./assets/fonts/source-sans-pro-v13-latin-italic.woff2) format('woff2'), 
       url(./assets/fonts/source-sans-pro-v13-latin-italic.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
       url(./assets/fonts/source-sans-pro-v13-latin-600.woff2) format('woff2'), 
       url(./assets/fonts/source-sans-pro-v13-latin-600.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-600italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'),
       url(./assets/fonts/source-sans-pro-v13-latin-600italic.woff2) format('woff2'), 
       url(./assets/fonts/source-sans-pro-v13-latin-600italic.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
       url(./assets/fonts/source-sans-pro-v13-latin-700.woff2) format('woff2'), 
       url(./assets/fonts/source-sans-pro-v13-latin-700.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-700italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'),
       url(./assets/fonts/source-sans-pro-v13-latin-700italic.woff2) format('woff2'), 
       url(./assets/fonts/source-sans-pro-v13-latin-700italic.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-900 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: local('Source Sans Pro Black'), local('SourceSansPro-Black'),
       url(./assets/fonts/source-sans-pro-v13-latin-900.woff2) format('woff2'), 
       url(./assets/fonts/source-sans-pro-v13-latin-900.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-900italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackItalic'),
       url(./assets/fonts/source-sans-pro-v13-latin-900italic.woff2) format('woff2'), 
       url(./assets/fonts/source-sans-pro-v13-latin-900italic.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}