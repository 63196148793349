.c16othqr{width:var(--c16othqr-0);-webkit-transition:width 0.3s;transition:width 0.3s;height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.w1t7nj4h{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;padding:5px 10px;-webkit-flex:1;-ms-flex:1;flex:1;}.w1t7nj4h > *{margin:5px 0px;}
.a1ikmfrw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;padding:5px 10px;-webkit-flex:1;-ms-flex:1;flex:1;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.c1sss3zk > *{margin:10px 0px;}
.d1f743g4{border-bottom:1px solid var(--sev1-primary-2-color);}
.cjius7s{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.c1qkhg96{font-size:calc(var(--sev1-size) * 0.8);}
.hrn91vq{font-size:calc(var(--sev1-size) * 0.9);}
.cw2af2g{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex:1;-ms-flex:1;flex:1;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.chil1a6{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin:5px 0px;}.chil1a6 > :first-child{margin-right:5px;}
.clwwjgf{font-size:calc(var(--sev1-size) * 0.7);}
.b1upe1r6{-webkit-flex:1;-ms-flex:1;flex:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;-webkit-align-self:flex-end;-ms-flex-item-align:end;align-self:flex-end;width:100%;}
.b1qzkl0e{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;width:100%;}
