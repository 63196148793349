.w6965i4{font-size:var(--sev1-size);position:relative;padding:8px;overflow:hidden;}


.svhfkjw{cursor:default;color:var(--sev1-primary-5-contrast);}
.i1an0r60{position:relative;padding:4px 0;white-space:nowrap;}
.t1cwgkpd{position:relative;cursor:pointer;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-text-decoration:none;text-decoration:none;overflow:hidden;height:32px;padding:8px;box-sizing:border-box;z-index:400;}.t1cwgkpd:before{position:absolute;top:0;right:0;bottom:0;left:0;border-radius:4px;content:'';-webkit-transform:scale(0.8);-ms-transform:scale(0.8);transform:scale(0.8);opacity:0;-webkit-transition:all .1s ease-in;transition:all .1s ease-in;background:var(--sev1-primary-2-color);z-index:-1;}.i5lo23r .t1cwgkpd:not(.svhfkjw),.t1cwgkpd:not(.svhfkjw):hover{color:var(--sev1-primary-2-contrast);}.i5lo23r .t1cwgkpd:not(.svhfkjw):before,.t1cwgkpd:not(.svhfkjw):hover:before{-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1);opacity:1;}
.i13h1mrh{-webkit-flex:none;-ms-flex:none;flex:none;width:16px;margin-right:16px;text-align:center;}.c7kw4la .i13h1mrh{width:calc(var(--sev1-nav-menu-compact-width) - 16px - 16px);height:1.2em;margin-right:0;text-align:center;}
.e1hpvees{cursor:pointer;margin-left:auto;padding-left:.75em;}
.ss1lskk{margin-left:2em;}
.pw6l5hl{z-index:2000;}
.szwvfnp{background:var(--sev1-primary-4-color);color:var(--sev1-primary-4-contrast);border-radius:4px;min-width:150px;padding:.2em .4em;box-shadow:0 1px 2px 0 rgba(0,0,0,0.25);margin-left:10px;}
.s6r42m3{display:block;position:relative;padding:2px 8px;-webkit-text-decoration:none;text-decoration:none;z-index:400;}.s6r42m3:before{position:absolute;top:0;right:0;bottom:0;left:0;border-radius:4px;content:'';-webkit-transform:scale(0.8);-ms-transform:scale(0.8);transform:scale(0.8);opacity:0;-webkit-transition:all .1s ease-in;transition:all .1s ease-in;background:var(--sev1-primary-2-color);z-index:-1;}.i5lo23r .s6r42m3:not(.svhfkjw),.s6r42m3:not(.svhfkjw):hover{color:var(--sev1-primary-2-contrast);}.i5lo23r .s6r42m3:not(.svhfkjw):before,.s6r42m3:not(.svhfkjw):hover:before{-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1);opacity:1;}
.syof53b{position:absolute;top:0;right:0;bottom:0;left:0;border-radius:4px;margin:.2em 0;background:var(--sev1-primary-5-color);z-index:200;}
