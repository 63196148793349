.d14yrda3{cursor:pointer;padding:10px 20px;box-sizing:border-box;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.d14yrda3:hover{background:var(--sev1-primary-2-color);color:var(--sev1-primary-2-contrast);}
.d94gvo9{padding-left:10px;}
.u1khaa3a{line-height:1.5;}
.ulr6wrk{margin:0 auto 0 10px;padding-right:10px;}
.u14cix17{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;font-weight:600;line-height:1.5;font-size:.75em;}
.t1v92o3f{font-size:.65em;}
.exacft{-webkit-text-decoration:none;text-decoration:none;}
.w12bj1oi{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.wfr6rjq{-webkit-align-self:center;-ms-flex-item-align:center;align-self:center;background-color:var(--sev1-info-color);width:.5em;height:.5em;margin-left:5px;border-radius:6px;}
.dp8mrwo{display:block !important;}
