html,
body,
#root {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

body {
  font-family: 'Source Sans Pro', Helvetica, sans-serif !important;
  scrollbar-width: thin;
  scrollbar-color: var(--sev1-primary-1-color) var(--sev1-primary-2-color);
}

#root {
  background: var(--sev1-primary-3-color);
  z-index: 0;
}

/*
 * For PDF generation we need the widgets to grow freely, and we do that by
 * unrestricting their height and allowing them to scroll. The pdf generator
 * then takes that scroll height and uses it as its height. The root of the app
 * though normally restricts root overflows, so we need to override that
 * behavior.
 */
html.pdf,
body.pdf,
#root.pdf {
  /* resets */
  position: initial;
  overflow: initial;
  background: none;
  /* pdf specific */
  height: 100%;
}

a {
  color: inherit;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--sev1-primary-2-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--sev1-primary-1-color);
  border-radius: 8px;
  border: 2px solid var(--sev1-primary-2-color);
}
