.react-grid-item.react-grid-placeholder {
  background-color: var(--sev1-primary-5-color);
}

.react-grid-item > .react-resizable-handle {
  background-image: none;
}

.react-grid-item > .react-resizable-handle-se {
  width: 12px;
  height: 12px;
}

.react-grid-item > .react-resizable-handle::after {
  border: none;
}
